<template>
  <div class="header" v-if="initialized">
    <upload-modal
      v-if="showUploadModal"
      @close="showUploadModal = false"
      :version="version"
    />
    <language-upload-modal
      v-if="!!showUploadForLanguage"
      :language="showUploadForLanguage"
      :config="config"
      :version="version"
      :resource="languageImportResource"
      @cancel="showUploadLanguage(null)"
    />

    <div class="navbar">
      <div class="title-nav">
        <router-link class="title" :to="{ name: 'root', params: routeParams }"
          >E+POS Configuratie
        </router-link>
      </div>

      <div class="versionnav" v-if="isVersionedResource">
        <select
          class="select-config"
          @change="selectConfig($event.target.value)"
          :value="config.id"
        >
          <option
            v-for="candidateConfig in configs"
            :key="candidateConfig.id"
            :value="candidateConfig.id"
          >
            {{ candidateConfig.name }}
          </option>
        </select>
        <select
          class="select-version"
          @change="selectVersion($event.target.value)"
          :value="version.id"
          :title="version.updated_at"
        >
          <option
            v-for="candidateVersion in config.versions"
            :key="candidateVersion.id"
            :title="candidateVersion.updated_at"
            :value="candidateVersion.id"
          >
            versie
            {{ candidateVersion.version }}
            {{
              candidateVersion.open
                ? ''
                : '- ' + dateTime(candidateVersion.updated_at)
            }}
            {{
              candidateVersion.message ? '- ' + candidateVersion.message : ''
            }}
          </option>
        </select>
        <template v-if="!config.new_report">
          <span class="label">Rapport oude stijl</span>
        </template>
      </div>

      <div class="languagebuttons" v-if="showReportLanguage">
        <LanguageSwitcher
          :language="reportLanguage"
          :languages="reportLanguages"
          :translation-import="!!languageImportResource"
          @setLanguage="(lang) => $emit('setReportLanguage', lang)"
          @downloadLanguage="downloadLanguage"
          @uploadLanguage="showUploadLanguage"
        />
      </div>
      <div class="languagebuttons" v-else-if="showPatientLanguage">
        <LanguageSwitcher
          :language="language"
          :languages="patientLanguages"
          :translation-import="!!languageImportResource"
          @setLanguage="(lang) => $emit('setLanguage', lang)"
          @downloadLanguage="downloadLanguage"
          @uploadLanguage="showUploadLanguage"
        />
      </div>

      <div class="versionbuttons" v-if="isVersionedResource">
        <button
          v-if="version.open"
          class="btn btn-light finish-version"
          @click="finishVersion"
          title="Versie afronden"
        >
          <font-awesome-icon icon="check" />
        </button>
        <button
          class="btn btn-light duplicate-config"
          @click="duplicateConfig"
          title="Vragenlijst dupliceren"
        >
          <font-awesome-icon icon="copy" />
        </button>
        <button
          class="btn btn-light create-config"
          @click="createConfig"
          title="Nieuwe vragenlijst maken"
        >
          <font-awesome-icon icon="asterisk" />
        </button>
        <button
          class="btn btn-light delete-config"
          @click="deleteConfig"
          title="Verwijder configuratie"
        >
          <font-awesome-icon icon="trash" />
        </button>
        <button
          class="btn btn-light download-version-migration"
          @click="downloadVersionMigration"
          title="Download migratiebestand van deze versie"
        >
          <font-awesome-icon
            v-if="isLoading === 'downloadVersionMigration'"
            icon="spinner"
          />
          <font-awesome-icon v-else icon="download" />
        </button>
        <button
          class="btn btn-light upload-version-migration"
          @click="uploadVersionMigration"
          title="Upload migratiebestand"
        >
          <font-awesome-icon
            v-if="isLoading === 'uploadVersionMigration'"
            icon="spinner"
          />
          <font-awesome-icon icon="upload" />
        </button>
      </div>

      <div class="menu">
        <router-link :to="{ name: 'profile' }" class="username">
          <font-awesome-icon :icon="['fal', 'user']" />
          {{ $root.user.name }}
        </router-link>
        <button class="btn btn-light logoutbutton" @click="logout">
          <font-awesome-icon :icon="['fal', 'sign-out']" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// This component name is a preexisting condition, disable inspection.
/* eslint vue/multi-word-component-names: 0 */
import moment from 'moment';
import UploadModal from '@/components/UploadModal.vue';
import api, {
  exportQuestionnaireLanguage,
  exportTemplateLanguage,
} from '@/api';
import LanguageSwitcher from './LanguageSwitcher';
import { supportedLanguages, supportedReportLanguages } from '@/languages';
import LanguageUploadModal from '@/components/LanguageUploadModal.vue';

export default {
  props: ['version', 'configs', 'resource', 'language', 'reportLanguage'],
  components: { LanguageUploadModal, LanguageSwitcher, UploadModal },
  data() {
    return {
      showUploadModal: false,
      showUploadForLanguage: null,
      config: null,
      isLoading: null,
      patientLanguages: supportedLanguages,
      reportLanguages: supportedReportLanguages,
    };
  },
  created() {
    if (this.version) {
      this.setConfig();
    }
  },
  computed: {
    routeParams() {
      return this.version ? { versionId: this.version.id } : {};
    },
    initialized() {
      return (
        !this.isVersionedResource ||
        (this.configs !== null && this.config !== null && this.version !== null)
      );
    },
    isVersionedResource() {
      return (
        this.resource !== 'exports' &&
        this.resource !== 'files' &&
        this.resource !== 'tenants' &&
        this.resource !== 'profile' &&
        this.resource !== 'validator'
      );
    },
    showPatientLanguage() {
      return this.isVersionedResource || this.resource === 'tenants';
    },

    // Whether the language switcher for report resources needs to be shown
    showReportLanguage() {
      return this.$route.meta?.isReportResource ?? false;
    },
    languageImportResource() {
      return this.$route.meta?.languageImportResource;
    },
  },
  watch: {
    version() {
      if (this.version) {
        this.setConfig();
      }
    },
  },
  methods: {
    downloadVersionMigration() {
      this.isLoading = 'downloadVersionMigration';
      return api
        .downloadVersionMigration(this.config, this.version)
        .finally(() => (this.isLoading = null));
    },
    uploadVersionMigration() {
      this.showUploadModal = true;
    },
    setConfig() {
      this.config = this.configs.find(
        (config) => config.id === this.version.config_id
      );
    },
    selectConfig(configId) {
      this.config = this.configs.find(
        (config) => config.id === parseInt(configId)
      );
      this.selectVersion(this.config.versions[0].id, true);
    },
    finishVersion() {
      this.$emit('popup', {
        title: 'Versie ' + this.version.version + ' afronden?',
        content: 'Korte omschrijving van de wijzigingen:',
        image: 'question',
        input: true,
        report_type: false,
        confirm: (data) => {
          this.$emit('popup', null);

          api.closeVersion(this.version.id, data.input).then((response) => {
            this.selectVersion(response.data.id);
            this.$emit('reloadConfigs');
          });
        },
        cancel: () => this.$emit('popup', null),
      });
    },
    duplicateConfig() {
      this.$emit('popup', {
        title:
          this.config.name +
          ' (versie ' +
          this.version.version +
          ') dupliceren?',
        content: 'Naam nieuwe vragenlijst:',
        image: 'question',
        input: true,
        report_type: true,
        confirm: (data) => {
          this.$emit('popup', null);

          api
            .duplicateConfig(this.version.id, {
              name: data.input,
              new_report: data.new_report,
            })
            .then((response) => {
              this.$emit('newConfig', response.data.id);
            });
        },
        cancel: () => this.$emit('popup', null),
      });
    },
    createConfig() {
      this.$emit('popup', {
        title: 'Nieuwe (lege) vragenlijst maken?',
        content: 'Naam nieuwe vragenlijst:',
        image: 'question',
        input: true,
        report_type: true,
        confirm: (data) => {
          this.$emit('popup', null);

          api
            .createConfig({ name: data.input, new_report: data.new_report })
            .then((response) => {
              this.$emit('newConfig', response.data.id);
            });
        },
        cancel: () => this.$emit('popup', null),
      });
    },
    deleteConfig() {
      this.$emit('popup', {
        title: 'Configuratie ' + this.config.name + ' verwijderen?',
        content: 'Weet je het zeker?',
        image: 'question',
        input: false,
        report_type: false,
        confirm: () => {
          this.$emit('popup', null);

          api.deleteConfig(this.config.id).then(() => {
            this.$emit('reloadConfigs');
          });
        },
        cancel: () => this.$emit('popup', null),
      });
    },
    selectVersion(versionId, clearEntity = false) {
      let name = this.$route.name;
      let params = this.$route.params;
      params.versionId = versionId;

      if (clearEntity) {
        delete params.questionId;
        delete params.sectionId;
        delete params.ruleId;
        delete params.templateId;
      }

      this.$router.push({ name, params });
    },
    dateTime(value) {
      return moment(value).format('D MMM YYYY, HH:mm');
    },
    logout() {
      sessionStorage.removeItem('token');
      this.$root.user = null;
      this.$router.push('/login');
    },
    downloadLanguage(lang) {
      const resource = this.languageImportResource;
      if (resource === 'templates') {
        exportTemplateLanguage(this.config, this.version, lang);
      } else if (resource === 'questionnaire') {
        exportQuestionnaireLanguage(this.config, this.version, lang);
      }
    },
    showUploadLanguage(language = null) {
      this.showUploadForLanguage = language;
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  background-color: $color-main;
  font-family: $font-family-light;
  padding: 0 20px 0 0;
  height: 54px;

  .navbar {
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .title,
    .link {
      color: white;
      display: inline-block;
      font-weight: normal;
      text-decoration: none;
      vertical-align: top;
      line-height: 20px;
      padding: 15px 20px 15px 20px;
    }

    .title {
      font-size: 20px;
      margin: 0 25px 0 0;
    }

    .link {
      font-size: 16px;
      opacity: 0.9;
    }

    .link.active,
    .link.router-link-active,
    .link:hover {
      opacity: 1;
      font-weight: bold;
    }

    .label {
      margin-left: 5px;
      color: #212529;
      background-color: #f8f9fa;
      display: inline-block;
      padding: 0.25em 0.4em;
      font-size: 75%;
      font-weight: 700;
      line-height: 1;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      border-radius: 0.25rem;
    }

    .logoutbutton {
      margin-top: 9px;
    }

    .title-nav {
      flex: 1;
    }

    .versionnav {
      margin-top: 5px;

      select {
        padding: 5px 10px;
        border-radius: 2px;
        margin: 9px 3px;
        font-size: 14px;
        max-width: 20rem;
      }

      .select-config {
        margin-left: 15px;
      }
    }

    .versionbuttons {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;

      button {
        margin: 0 5px;
      }
    }

    .languagebuttons {
      flex: 2;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      button {
        margin: 0 1px;
      }

      button.btn-dark {
        font-weight: bold;
        border-color: white;
        cursor: default;
      }

      button.btn-dark:hover {
        background-color: rgb(106, 16, 58);
      }
    }
  }

  .username {
    color: white;
    display: inline-block;
    font-weight: normal;
    text-decoration: none;
    vertical-align: top;
    line-height: 20px;
    padding: 15px 25px 15px 25px;
    cursor: pointer;
  }
}
</style>
