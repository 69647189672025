<template>
  <div class="container" v-if="viewActive">
    <div class="subheader">
      <div class="title">
        {{ $route.params.tenantId ? 'Tenant bewerken' : 'Nieuwe tenant' }}
      </div>
      <div class="buttons">
        <button class="btn btn-light" @click="openTestSaml()">
          SAML tests
        </button>
        <button class="btn btn-dark" :disabled="!canSave" @click="saveTenant()">
          Opslaan
        </button>
      </div>
    </div>
    <div class="scroller">
      <div class="errors" v-if="errors">
        {{ errors }}
      </div>
      <div class="form">
        <div class="field">
          <label>ID</label>
          <input type="text" disabled :value="tenant.id" />
        </div>

        <div class="field">
          <label>Naam</label>
          <input type="text" v-model="tenant.name" />
        </div>

        <div class="field">
          <label>Domein</label>
          <select v-model="tenant.domain">
            <option
              v-for="option in domainOptions"
              :key="option.value"
              :value="option.value"
            >
              {{ option.name }}
            </option>
          </select>
        </div>

        <div class="field">
          <label>Status</label>
          <select v-model="tenant.status">
            <option :value="null"></option>
            <option value="demo">demo</option>
            <option value="production">production</option>
            <option value="validation">validation</option>
          </select>
        </div>

        <div class="field">
          <label>Workflow</label>
          <select v-model="tenant.workflow">
            <option
              v-for="workflow in workflows"
              :value="workflow.key"
              :key="workflow.key"
            >
              {{ workflow.name }}
            </option>
          </select>
        </div>

        <!-- All configuration selectors -->
        <div
          v-for="(selector, i) in configurationSelectors"
          class="field"
          :key="`config-${selector.key}`"
        >
          <label>{{ selector.name }} configuratie</label>
          <select v-model="selectionIds[i].configId.value">
            <option :value="null"></option>
            <option
              v-for="config in configs"
              :key="config.id"
              :value="config.id"
            >
              {{ config.name }}
            </option>
          </select>
          <label class="version">versie</label>
          <select class="version" v-model="selectionIds[i].versionId.value">
            <option :value="null" v-if="!tenant[selector.key]"></option>
            <option
              v-for="version in selectionIds[i].versions.value"
              :key="version.id"
              :value="version.id"
            >
              {{ version.version }}
            </option>
          </select>
        </div>

        <div class="field nested-field allowed-ips">
          <label>Allowed IPs</label>
          <div class="subfield-container">
            <div
              class="subfield"
              v-for="(ip, idx) in tenant.allowed_ips_labeled"
              :key="ip.key"
            >
              <div class="field">
                <label>Label</label>
                <input type="text" v-model="ip.label" />
                <button class="btn btn-red" @click="deleteAllowedIp(idx)">
                  Verwijderen
                </button>
              </div>
              <div class="field">
                <label>IP</label>
                <input type="text" v-model="ip.ip" />
              </div>
            </div>
            <button class="btn btn-dark" @click="addAllowedIp()">
              Toevoegen
            </button>
          </div>
        </div>

        <div class="field">
          <label
            title="Bepaalt of de patiënt met een extra SMS-code moet authenticeren"
            >Patiënt two-factor</label
          >
          <select v-model="tenant.patient_twofactor">
            <option :value="true">Ja</option>
            <option :value="false">Nee</option>
          </select>
        </div>

        <div class="field">
          <label
            title="Bepaalt of de gebruikers met een SMS-code moeten authenticeren"
            >Admin two-factor</label
          >
          <select v-model="tenant.two_factor">
            <option :value="true">Ja</option>
            <option :value="false">Nee</option>
          </select>
        </div>

        <div class="field">
          <label
            title="Bepaalt of de gebruikers met de Google Authenticator App kunnen authenticeren"
            >Google Authenticator App</label
          >
          <select v-model="tenant.authenticator">
            <option :value="true">Ja</option>
            <option :value="false">Nee</option>
          </select>
        </div>

        <div class="field">
          <label>Taalkeuzes (screening)</label>
          <vue-multiselect
            placeholder=""
            :options="languageOptions"
            track-by="value"
            label="name"
            :searchable="false"
            :multiple="true"
            :close-on-select="false"
            :allow-empty="false"
            :show-labels="false"
            v-model="tenantLanguages"
          />
        </div>

        <div class="field">
          <label>Taalkeuzes (kindvragenlijst)</label>
          <vue-multiselect
            placeholder=""
            :options="languageOptions"
            track-by="value"
            label="name"
            :searchable="false"
            :multiple="true"
            :close-on-select="false"
            :allow-empty="false"
            :show-labels="false"
            v-model="tenantChildLanguages"
          />
        </div>

        <div class="field">
          <label>Admin locale</label>
          <select v-model="tenant.locale">
            <option
              v-for="option in adminLanguageOptions"
              :value="option.value"
              :key="option.value"
            >
              {{ option.name }} ({{ option.value }})
            </option>
          </select>
        </div>

        <div class="field">
          <label>Patiënt locale</label>
          <select v-model="tenant.patient_locale">
            <option :value="null">(gelijk aan admin locale)</option>
            <option
              v-for="option in languageOptions"
              :key="option.value"
              :value="option.value"
            >
              {{ option.name }}
            </option>
          </select>
        </div>

        <div class="field">
          <label>Export Templates</label>
          <vue-multiselect
            placeholder=""
            :options="exportTemplates"
            v-model="selectedExportTemplates"
            track-by="id"
            label="name"
            :searchable="false"
            :multiple="true"
            :close-on-select="false"
            :allow-empty="true"
            :show-labels="false"
          />
        </div>

        <div class="field nested-field locations">
          <label>Locaties</label>
          <div class="subfield-container">
            <div
              class="subfield"
              v-for="(location, idx) in tenant.locations"
              :key="location.key"
            >
              <div class="field">
                <label>ID</label>
                <input type="text" :value="location.id" disabled />
                <button class="btn btn-red" @click="deleteLocation(idx)">
                  Verwijderen
                </button>
              </div>
              <div class="field">
                <label>Extern referentie-ID</label>
                <input type="text" v-model="location.external_reference_id" />
              </div>
              <div class="field">
                <label>Locatie (kort)</label>
                <input v-model="location.short_name" type="text" />
              </div>
              <div class="field">
                <label>Locatie (lang)</label>
                <input v-model="location.full_name" type="text" />
              </div>
              <div class="field">
                <label>Telefoon poli</label>
                <input v-model="location.phone" type="text" />
              </div>
              <div class="field">
                <label>E-mailadres poli</label>
                <input v-model="location.email" type="text" />
              </div>
              <div class="field">
                <label>Ziekenhuis naam ({{ language.toUpperCase() }})</label>
                <multi-language-input
                  :language="language"
                  v-model="location.hospital_name"
                />
              </div>
              <div class="field">
                <label
                  >Openingstijden poli ({{ language.toUpperCase() }})</label
                >
                <multi-language-input
                  :language="language"
                  v-model="location.availability"
                />
              </div>
            </div>
            <button class="btn btn-dark" @click="addLocation()">
              Toevoegen
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import VueMultiselect from 'vue-multiselect';
import {
  emptyLanguageObject,
  languageLabels,
  supportedLanguages,
  supportedReportLanguages,
} from '@/languages';
import { computed, onMounted, ref, watch } from 'vue';
import MultiLanguageInput from '@/components/MultiLanguageInput.vue';
import { cloneDeep, flatMap, isEqual, maxBy, random } from 'lodash';
import {
  createTenant,
  getConfigs,
  getDomains,
  getExportTemplates,
  getTenant,
  listAllWorkflows,
  updateTenant,
} from '@/api';
import { useRoute, useRouter } from 'vue-router/composables';

defineProps({
  language: String,
});

function allowedIpFactory(props = {}) {
  return {
    key: random(0, 999999),
    id: null,
    label: '',
    ip: '',
    ...props,
  };
}

function locationFactory(props = {}) {
  return {
    // View iteration key
    key: random(0, 999999),
    id: null,
    external_reference_id: null,
    short_name: '',
    full_name: '',
    phone: '',
    email: '',
    availability: emptyLanguageObject(supportedLanguages),
    hospital_name: emptyLanguageObject(supportedLanguages),
    ...props,
  };
}

function factory(props = {}) {
  return {
    name: '',
    locale: 'nl',
    patient_locale: 'nl',
    domain: 'nl',
    oid: null,
    version_id: null,
    questionnaire_end_version_id: null,
    control_questionnaire_version_id: null,
    proms_dash_questionnaire_version_id: null,
    proms_prwhe_questionnaire_version_id: null,
    child_questionnaire_version_id: null,
    patient_twofactor: true,
    informed_consent_version_id: null,
    overview_version_id: null,
    two_factor: false,
    authenticator: false,
    patient_data_in_summary: false,
    workflow: 'default',
    status: 'demo',
    template_handle: null,
    video_handle: null,
    supported_languages: ['nl', 'en'],
    supported_child_languages: ['nl'],
    retention_period: null,
    cns_connect_url: null,
    cns_connect_enabled: false,
    export_template_ids: null,
    allowed_ips_labeled: [],
    locations: [locationFactory()],
    ...props,
  };
}

const configurationSelectors = [
  {
    name: 'Vragenlijst',
    key: 'version_id',
  },
  {
    name: 'Informed consent',
    key: 'informed_consent_version_id',
  },
  {
    name: 'Kindervragenlijst',
    key: 'child_questionnaire_version_id',
  },
  {
    name: 'Patiënt overzichtspagina',
    key: 'overview_version_id',
  },
  {
    name: 'Einde vragenlijst',
    key: 'questionnaire_end_version_id',
  },
  {
    name: 'Controle vragenlijst',
    key: 'control_questionnaire_version_id',
  },
  {
    name: 'Proms Dash vragenlijst',
    key: 'proms_dash_questionnaire_version_id',
  },
  {
    name: 'Proms Prwhe vragenlijst',
    key: 'proms_prwhe_questionnaire_version_id',
  },
];

// Create a list of computed properties for the config ID / version ID.
// This avoids two problems:
//
// - Using functions dynamically inside a template will recalculate them each time
//   the template updates for some reason, computed properties won't.
// - The options for our select boxes are dynamic, which can cause a :value to
//   be out of sync with the actual options available. v-model has some functionality
//   to prevent this which we can rely on, which we can do if we have a computed
//   property to pass in.
//   https://stackoverflow.com/questions/69817201/select-not-rendering-new-value-when-changing-options-dynamically-with-vuejs
//
// Note that to use a computed property from an array in the template, you have to unwrap it manually
// (you have to do selectionIds[0].configId.value, it'll break without the .value).
const selectionIds = configurationSelectors.map((selector) => ({
  configId: computed({
    get() {
      return selectedConfig(selector)?.id;
    },
    set(v) {
      selectConfig(selector, v);
    },
  }),
  versionId: computed({
    get() {
      return tenant.value[selector.key] ?? null;
    },
    set(v) {
      selectVersion(selector, v);
    },
  }),
  versions: computed(() => configVersions(selector)),
}));

function selectedConfig(selector) {
  const versionId = tenant.value[selector.key] ?? null;

  return versionId
    ? configs.value.find((config) =>
        config.versions.some((v) => v.id === versionId)
      ) ?? null
    : null;
}

function selectConfig(selector, value) {
  const config = value
    ? configs.value.find((c) => c.id === parseInt(value))
    : null;

  if (!config) {
    tenant.value[selector.key] = null;
    return;
  }

  // Select the last available version by default
  const lastVersion = maxBy(config.versions, (v) => v.version);
  tenant.value[selector.key] = lastVersion.id;
}

function selectVersion(selector, value) {
  tenant.value[selector.key] = parseInt(value) || null;
}

function configVersions(selector) {
  const config = selectedConfig(selector);
  return config ? config.versions : [];
}

const languageOptions = supportedLanguages.map((language) => ({
  name: languageLabels[language],
  value: language,
}));

const adminLanguageOptions = supportedReportLanguages.map((language) => ({
  name: languageLabels[language],
  value: language,
}));

const tenant = ref(factory());

const tenantLanguages = computed({
  get() {
    return (tenant.value.supported_languages ?? []).map((language) =>
      languageOptions.find((option) => option.value === language)
    );
  },
  set(value) {
    tenant.value.supported_languages = value.map((v) => v.value);
  },
});

const tenantChildLanguages = computed({
  get() {
    return (tenant.value.supported_child_languages ?? []).map((language) =>
      languageOptions.find((option) => option.value === language)
    );
  },
  set(value) {
    tenant.value.supported_child_languages = value.map((v) => v.value);
  },
});

function addAllowedIp() {
  tenant.value.allowed_ips_labeled.push(allowedIpFactory());
}

function deleteAllowedIp(index) {
  tenant.value.allowed_ips_labeled.splice(index, 1);
}

function addLocation() {
  tenant.value.locations.push(locationFactory());
}

function deleteLocation(index) {
  tenant.value.locations.splice(index, 1);
}

const configs = ref([]);

async function loadConfigs() {
  configs.value = (await getConfigs()).data;
}

const workflows = ref([]);

async function loadWorkflows() {
  workflows.value = (await listAllWorkflows(tenant.value.domain)).data;
}

const domainOptions = ref([]);

async function loadDomains() {
  domainOptions.value = (await getDomains()).data;
}

const exportTemplates = ref([]);

async function loadExportTemplates() {
  exportTemplates.value = (await getExportTemplates()).data;
}

const selectedExportTemplates = computed({
  get() {
    return (tenant.value.export_template_ids ?? []).map((id) =>
      exportTemplates.value.find((t) => t.id === id)
    );
  },
  set(value) {
    tenant.value.export_template_ids = value.map((v) => v.id);
  },
});

const viewActive = ref(false);

const router = useRouter();
const route = useRoute();

const originalValue = ref(null);

function newTenant() {
  tenant.value = factory();
  originalValue.value = null;
  viewActive.value = true;
}

async function loadTenant() {
  const data = (await getTenant(parseInt(route.params.tenantId))).data;
  tenant.value = factory(data);
  originalValue.value = cloneDeep(tenant.value);
  viewActive.value = true;
}

const errors = ref('');

// Returns the tenant with any "key" properties in lists removed.
function cleanTenant() {
  const value = cloneDeep(tenant.value);

  // Don't send `key`s to the API
  value.allowed_ips_labeled?.forEach((ip) => {
    delete ip.key;
  });

  value.locations?.forEach((location) => {
    delete location.key;
  });

  return value;
}

async function saveTenant() {
  errors.value = '';

  try {
    if (tenant.value.id) {
      await updateTenant(cleanTenant());
      originalValue.value = cloneDeep(tenant.value);
    } else {
      const response = await createTenant(tenant.value);
      await router.push({
        name: 'tenants',
        params: { tenantId: response.data.id },
      });
    }
  } catch (e) {
    if (e.response?.data?.errors) {
      errors.value = flatMap(e.response.data.errors, (v) => v).join(', ');
      return;
    }

    throw e;
  }
}

const canSave = computed(
  () =>
    originalValue.value === null || !isEqual(originalValue.value, tenant.value)
);

watch(
  () => route.params,
  () => {
    if (route.params.tenantId === 'new') {
      newTenant();
      return;
    }

    if (route.params.tenantId) {
      loadTenant();
      return;
    }

    tenant.value = factory();
    originalValue.value = null;
    viewActive.value = false;
  },
  { immediate: true }
);

function openTestSaml() {
  router.push({
    name: 'tenantSamlTest',
    params: route.params,
    query: route.query,
  });
}

onMounted(() => {
  loadDomains();
  loadConfigs();
  loadExportTemplates();
});

// The list of available workflows depends on the tenant region.
// Reload the list when the region changes.
watch(
  () => tenant.value.domain,
  () => loadWorkflows(),
  { immediate: true, flush: 'post' }
);
</script>

<style scoped lang="scss">
@import '@/assets/mixins.scss';

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: $font-family-gitlab;
  font-size: 14px;
}

.field {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.subfield-container {
  flex-grow: 1;

  label {
    width: 100px;
  }

  // This is for the "delete" buttons inside the nested fields
  .field .btn {
    align-self: center;
    margin-left: 0.5rem;
  }
}

.subfield {
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

label {
  @include input-label(200px);
}

label.version {
  width: auto;
  margin-left: 0.5rem;
}

select.version {
  width: 100px;
  flex-grow: 0;
}

.scroller {
  overflow-y: auto;
}

.form {
  padding: 1rem;
}

input,
textarea,
select {
  @include input-box;
  line-height: 1.15;
  flex-grow: 1;
}

.multiselect {
  width: auto;
  flex-grow: 1;
}

.errors {
  font-size: 14px;
  font-weight: bold;
  color: $color-dark-red;
  padding: 1rem;
}
</style>
